<template>
           <!-- =====================================
        ==== Start Works -->

        <section class="works section-padding" data-scroll-index="2">
            <div class="container">
                <div class="row">
                    
                    <div class="section-head offset-md-2 col-md-8 offset-lg-3 col-lg-6">
                        <h4><span>Our</span> Products</h4>
                        <p>A product description is the marketing copy that explains what a product is and why it’s worth purchasing.</p>
                    </div>

                </div>
            </div>

            <div class="container-fluid">
                <div class="row">

                    <!-- filter links -->
                    <!-- <div class="filtering text-center mb-30 col-sm-12">
                        <div class="filter">
                            <span data-filter='*' class="active">All</span>
                            <span data-filter='.brand'>Brand</span>
                            <span data-filter='.web'>Design</span>
                            <span data-filter='.graphic'>Graphic</span>
                        </div>
                    </div>
                    <div class="clearfix"></div> -->

                    <!-- gallery -->
                    <div class="gallery full-width">

                        <!-- gallery item -->
                        <div class="col-lg-3 col-md-6 items graphic">
                            <div class="item-img">
                                <img src="../../assets/img/portfolio/sms.png" alt="image">
                                <div class="item-img-overlay">
                                    <div class="overlay-info full-width">
                                        <p>Logo | Branding</p>
                                        <h6>Bulk SMS</h6>
                                        <a href="assets/img/portfolio/sms.png" class="popimg">
                                            <span class="icon"><i class="fas fa-long-arrow-alt-right"></i></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- gallery item -->
                        <div class="col-lg-3 col-md-6 items web">
                            <div class="item-img">
                                <img src="../../assets/img/portfolio/CallCenter.png" alt="image">
                                <div class="item-img-overlay">
                                    <div class="overlay-info full-width">
                                        <p>Logo | Branding</p>
                                        <h6>Call Center</h6>
                                        <a href="../../assets/img/portfolio/CallCenter.png" class="popimg">
                                            <span class="icon"><i class="fas fa-long-arrow-alt-right"></i></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- gallery item -->
                        <div class="col-lg-3 col-md-6 items brand">
                            <div class="item-img">
                                <img src="../../assets/img/portfolio/SDP.png" alt="image">
                                <div class="item-img-overlay">
                                    <div class="overlay-info full-width">
                                        <p>Logo | Branding</p>
                                        <h6>SDP System</h6>
                                        <a href="../../assets/img/portfolio/SDP.png" class="popimg">
                                            <span class="icon"><i class="fas fa-long-arrow-alt-right"></i></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- gallery item -->
                        <div class="col-lg-3 col-md-6 items graphic">
                            <div class="item-img">
                                <img src="../../assets/img/portfolio/PromtionSystem.png" alt="image">
                                <div class="item-img-overlay">
                                    <div class="overlay-info full-width">
                                        <p>Logo | Branding</p>
                                        <h6>Promotion System</h6>
                                        <a href="../../assets/img/portfolio/PromtionSystem.png" class="popimg">
                                            <span class="icon"><i class="fas fa-long-arrow-alt-right"></i></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- gallery item -->
                        <div class="col-lg-3 col-md-6 items web">
                            <div class="item-img">
                                <img src="../../assets/img/portfolio/examination.jpg" alt="image">
                                <div class="item-img-overlay">
                                    <div class="overlay-info full-width">
                                        <p>Logo | Branding</p>
                                        <h6>Examination System</h6>
                                        <a href="../../assets/img/portfolio/examination.jpg" class="popimg">
                                            <span class="icon"><i class="fas fa-long-arrow-alt-right"></i></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- gallery item -->
                        <div class="col-lg-3 col-md-6 items brand">
                            <div class="item-img">
                                <img src="../../assets/img/portfolio/TicktingSystem.jpg" alt="image">
                                <div class="item-img-overlay">
                                    <div class="overlay-info full-width">
                                        <p>Logo | Branding</p>
                                        <h6>Ticking System</h6>
                                        <a href="../../assets/img/portfolio/TicktingSystem.jpg" class="popimg">
                                            <span class="icon"><i class="fas fa-long-arrow-alt-right"></i></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- gallery item -->
                        <div class="col-lg-3 col-md-6 items brand">
                            <div class="item-img">
                                <img src="../../assets/img/portfolio/ContentCreation.jpg" alt="image">
                                <div class="item-img-overlay">
                                    <div class="overlay-info full-width">
                                        <p>Logo | Branding</p>
                                        <h6>Content creation</h6>
                                        <a href="../../assets/img/portfolio/ContentCreation.jpg" class="popimg">
                                            <span class="icon"><i class="fas fa-long-arrow-alt-right"></i></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>

        <!-- End Works ====
        ======================================= -->
</template>

<script>
export default {
   name: 'OurProducts'
}
</script>

<style>

</style>