<template>
            <!-- =====================================
        ==== Start Call-Action -->
        <!-- <section class="call-action section-padding bg-img bg-fixed" data-overlay-dark="5" data-background="static/img/bg2.jpg">
            <div class="container">
                <div class="row">
                    
                    <div class="col-lg-12">
                        <div class="text-center">
                            <h2>Want to work with us?</h2>
                            <h5>Tell us about your project</h5>
                            <a href="#0" class="butn butn-bg">
                                <span>Get Started</span>
                            </a>
                        </div>
                    </div>

                </div>
            </div>
        </section> -->

        <!-- End Call-Action ====
        ======================================= -->

        <!-- =====================================
        ==== Start Contact -->

       <section class="contact section-padding" data-scroll-index="6">
           <div class="container">
               <div class="row">
                    <div class="section-head offset-md-2 col-md-8 offset-lg-3 col-lg-6">
                        <h4>Get <span>In</span> Touch</h4>
                        <p>You can get in touch by filling in our form below.</p>
                    </div>
                    <div class="col-lg-5">
                        <div class="contact-info mb-md50">
                            <h5>Contact Us :</h5>
                            <p>Just send us your questions or concerns by starting a new case and we will give you the help you need.</p>
                            <div class="item">
                                <span class="icon icon-basic-tablet"></span>
                                <div class="cont">
                                    <h6>Phone : </h6>
                                    <p>+249 912505601 , +249 123901415</p>
                                </div>
                            </div>
                            <div class="item">
                                <span class="icon icon-basic-mail-open-text"></span>
                                <div class="cont">
                                    <h6>Email : </h6>
                                    <p>info@sudavas.com</p>
                                </div>
                            </div>
                            <div class="item">
                                <span class="icon icon-basic-geolocalize-05"></span>
                                <div class="cont">
                                    <h6>Address : </h6>
                                    <p>Riyadh Blocl 21 , Khartoum Sudan</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-7">
                        <form class="form" id="contact-form" method="post" action="">
                            <div class="messages"></div>
                            <div class="controls">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <input id="form_name" type="text" name="name" placeholder="Name" required="required">
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <input id="form_email" type="email" name="email" placeholder="Email" required="required">
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <input id="form_subject" type="text" name="subject" placeholder="Subject">
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <textarea id="form_message" name="message" placeholder="Message" rows="4" required="required"></textarea>
                                        </div>
                                    </div>

                                    <div class="col-md-12">
                                        <button type="submit" class="butn butn-bg"><span>Send Message</span></button>
                                    </div>
                                </div>                             
                            </div>
                        </form>
                    </div>

               </div>
           </div>
       </section>

        <!-- End Contact ====
        ======================================= -->
</template>

<script>
export default {
  name: 'AppContacts'
}
</script>

<style>

</style>