<template>
           <!-- =====================================
        ==== Start Teams -->

        <section class="team section-padding" data-scroll-index="3">
            <div class="container">
                <div class="row">

                    <div class="section-head offset-md-2 col-md-8 offset-lg-3 col-lg-6">
                        <h4><span>Our</span> Team</h4>
                        <p>Everyone says it, but in our case it’s true: our team is the secret to our success. Each of our employees is amazing in their own right, but together they are what makes SudaVas such a fun and rewarding place to work.</p>
                    </div>
                    
                    <div class="owl-carousel owl-theme">
                        <div class="item">
                            <div class="team-img">
                                <img src="../../assets/img/person-icon.png" alt="">
                                <div class="social">
                                    <a href="" class="icon">
                                        <i class="fab fa-facebook-f"></i>
                                    </a>
                                    <a href="" class="icon">
                                        <i class="fab fa-twitter"></i>
                                    </a>
                                </div>
                            </div>
                            <div class="info">
                                <h6>Mohammed Ismail</h6>
                                <span>CEO</span>
                            </div>
                        </div>
                    
                        <div class="item">
                            <div class="team-img">
                                <img src="../../assets/img/person-icon.png" alt="">
                                <div class="social">
                                    <a href="" class="icon">
                                        <i class="fab fa-facebook-f"></i>
                                    </a>
                                    <a href="" class="icon">
                                        <i class="fab fa-twitter"></i>
                                    </a>
                                </div>
                            </div>
                            <div class="info">
                                <h6>Abuhorira Harry</h6>
                                <span>CTO</span>
                            </div>
                        </div>

                        <div class="item">
                            <div class="team-img">
                                <img src="../../assets/img/person-icon.png" alt="">
                                <div class="social">
                                    <a href="" class="icon">
                                        <i class="fab fa-facebook-f"></i>
                                    </a>
                                    <a href="" class="icon">
                                        <i class="fab fa-twitter"></i>
                                    </a>
                                </div>
                            </div>
                            <div class="info">
                                <h6>Mahamoud Abdallah</h6>
                                <span>Project Manager</span>
                            </div>
                        </div>

                        <div class="item">
                            <div class="team-img">
                                <img src="../../assets/img/person-icon.png" alt="">
                                <div class="social">
                                    <a href="" class="icon">
                                        <i class="fab fa-facebook-f"></i>
                                    </a>
                                    <a href="#0" class="icon">
                                        <i class="fab fa-twitter"></i>
                                    </a>
                                </div>
                            </div>
                            <div class="info">
                                <h6>Ahmed Mohamed</h6>
                                <span>Project Manager</span>
                            </div>
                        </div>

                        <!-- <div class="item">
                            <div class="team-img">
                                <img src="../../assets/img/team/3.jpg" alt="">
                                <div class="social">
                                    <a href="#0" class="icon">
                                        <i class="fab fa-facebook-f"></i>
                                    </a>
                                    <a href="#0" class="icon">
                                        <i class="fab fa-twitter"></i>
                                    </a>
                                    <a href="#0" class="icon">
                                        <i class="fab fa-behance"></i>
                                    </a>
                                </div>
                            </div>
                            <div class="info">
                                <h6>Alex Smith</h6>
                                <span>Project Manager</span>
                            </div>
                        </div> -->
                    </div>

                </div>
            </div>
        </section>

        <!-- End Teams ====
        ======================================= -->
</template>

<script>
export default {
  name: 'TalentTeam'
}
</script>

<style>

</style>