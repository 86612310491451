<template>
  <!-- ========================================= Start Navbar -->
		<nav class="navbar navbar-expand-lg">
			<div class="container">
            <!-- Logo -->
            <router-link to="/" class="logo" >
                <img src="static/img/logo-light.png" alt="SudaVas">          
            </router-link>
			  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
			    <span class="icon-bar"><i class="fas fa-bars"></i></span>
			  </button>
			  <!-- navbar links -->
			  <div class="collapse navbar-collapse" id="navbarSupportedContent">
			    <ul class="navbar-nav ml-auto">
			      <li class="nav-item">
			        <a class="nav-link active" href="#" data-scroll-nav="0">Home</a>
			      </li>
			      <li class="nav-item">
                    <a class="nav-link" href="#" data-scroll-nav="1">Services</a>
                  </li>
			      <li class="nav-item">
			        <a class="nav-link" href="#" data-scroll-nav="2">Products</a>
			      </li>
                  <li class="nav-item">
                    <a class="nav-link" href="#" data-scroll-nav="3">Team</a>
                  </li>
			      <!-- <li class="nav-item">
			        <a class="nav-link" href="#" data-scroll-nav="4">Price</a>
			      </li>
			      <li class="nav-item">
			        <a class="nav-link" href="#" data-scroll-nav="5">Blog</a>
			      </li> -->
			      <li class="nav-item">
			        <a class="nav-link" href="#" data-scroll-nav="6">Contact</a>
			      </li>
			    </ul>
			  </div>
			</div>
		</nav>
    	<!-- End Navbar =========================================== -->
</template>

<script>
export default {
    name: 'AppNavbar',
    // mounted() {
    //     const s = document.createElement('script');
    //        s.type = 'text/javascript';
    //        s. src = 'static/js/scripts.js';
    //        document.body.appendChild(s);        
    // },
}
</script>

<style>

</style>