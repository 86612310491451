<template>
  <!-- ========================================= Start Header -->
    	<header class="header pos-re slider-fade" data-scroll-index="0">
    		<div class="owl-carousel owl-theme">
                <div class="item bg-img" data-overlay-dark="5" data-background="static/img/bg4.jpg">
        			<div class="text-center v-middle caption mt-30">
                        <h4>We Are</h4>
                        <h1>Big VAS Company</h1>
                        <div class="row">
                            <div class="offset-md-1 col-md-10 offset-lg-2 col-lg-8">
                                <p>We specialize in providing content to telecom companies.</p>
                            </div>
                        </div>
                        <router-link to="/" class="butn butn-light mt-30">
                            <span>Get Started</span>
                        </router-link>
                    </div>
                </div>
                <div class="item bg-img" data-overlay-dark="5" data-background="static/img/bg1.jpg">
                    <div class="text-center v-middle caption mt-30">
                        <h4>We Are</h4>
                        <h1>BIG SOFTWARE COMPANY </h1>
                        <div class="row">
                            <div class="offset-md-1 col-md-10 offset-lg-2 col-lg-8">
                                <p>We specialize in providing technical and software solutions in the field of VAS.</p>
                            </div>
                        </div>
                        <router-link to="/" class="butn butn-light mt-30">
                            <span>Get Started</span>
                        </router-link>
                    </div>
                </div>
                <div class="item bg-img" data-overlay-dark="5" data-background="static/img/bg2.jpg">
                    <div class="text-center v-middle caption mt-30">
                        <h4>We Are</h4>
                        <h1>Big VAS Company</h1>
                        <div class="row">
                            <div class="offset-md-1 col-md-10 offset-lg-2 col-lg-8">
                                <p>We specialize in providing content to telecom companies.</p>
                            </div>
                        </div>
                        <router-link to="/" class="butn butn-light mt-30">
                            <span>Get Started</span>
                        </router-link>
                    </div>
                </div>
    		</div>
    	</header>
    	<!-- End Header =========================================== -->
</template>

<script>
export default {
   name: 'AppHeader'
}
</script>

<style>

</style>