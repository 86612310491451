<template>
           <!-- =====================================
        ==== Start Process -->

        <div class="process section-padding bg-img bg-fixed pos-re text-center" data-overlay-dark="7" data-background="static/img/bg6.jpg">
            <div class="container">
                <div class="row">

                    <div class="section-head offset-md-2 col-md-8 offset-lg-3 col-lg-6">
                        <h4><span>Our</span> Process</h4>
                        <p>We follow systematic methods to reach our products to customers .</p>
                    </div>
                    <div class="full-width clearfix"></div>
                    
                    <div class="col-lg-3 col-md-6">
                        <div class="item first mb-md50">
                            <img src="../../assets/img/arrow.png" class="tobotm" alt="">
                            <span class="icon icon-basic-lightbulb"></span>
                            <div class="cont">
                                <h3>01</h3>
                                <h6>Ideas</h6>
                                <p>With an understand of key users behaviours and common traits and grouping through personas, we move on to making well informed decisions on how best to map human experience to business requirements through a set of collaborative workshops and precursory design briefings.</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-6">
                        <div class="item odd mb-md50">
                            <img src="../../assets/img/arrow.png" alt="">
                            <span class="icon icon-basic-book-pencil"></span>
                            <div class="cont">
                                <h3>02</h3>
                                <h6>Planning</h6>
                                <p>All human centered design starts with an understanding of users. Our team investigates and identifies user needs, wants and desires based on current market, solutions or scenarios and synthesise this into results that inform product design and development.</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-6">
                        <div class="item mb-sm50">
                            <img src="../../assets/img/arrow.png" class="tobotm" alt="">
                            <span class="icon icon-basic-pencil-ruler"></span>
                            <div class="cont">
                                <h3>03</h3>
                                <h6>Development</h6>
                                <p>th the product ideation phase completed and a clear set of user workflows mapped out, we can confidently move forward with the all important visual phase of our process.</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-6">
                        <div class="item odd">
                            <span class="icon icon-basic-server-upload"></span>
                            <div class="cont">
                                <h3>04</h3>
                                <h6>Testing</h6>
                                <p>With build commenced and stable release of features and functionality planned, we begin preparation for usability testing, organising in person recorded sessions with users to validate the design and build through real world interaction. </p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="curve curve-gray-t curve-top"></div>
            <div class="curve curve-bottom"></div>
        </div>

        <!-- End Process ====
        ======================================= -->
</template>

<script>
export default {
   name: 'OurProcess'
}
</script>

<style>

</style>