<template>
          <!-- =====================================
        ==== Start Hero -->

        <section class="hero" data-scroll-index="1">
            <div class="section-padding pos-re">
                <div class="container">
                    <div class="row">
                        <div class="offset-lg-2 col-lg-8 offset-md-1 col-md-10 text-center mb-80">
                            <h4 class="extra-title">About Our <span>Creative</span> Company</h4>
                            <p>We believe:  The best brands are built from the inside out. Values trump everything. A business can do good and be good. Inclusion inspires innovation.</p>
                        </div>

                        <div class="feat-item mb-md50 col-lg-3">
                            <div class="text-center">
                                <span class="icon icon-basic-gear"></span>
                                <h5>Software and Web Development</h5>
                                <!-- <h6>Modern & Clean Design</h6> -->
                                <p>We are working to transform your ideas into a tangible technical project</p>
                            </div>
                        </div>

                        <div class="feat-item active mb-md50 col-lg-3">
                            <div class="text-center">
                                <span class="icon icon-basic-smartphone"></span>
                                <h5>Mobile VAS</h5>
                                <!-- <h6>Web & Mobile Development</h6> -->
                                <p>We work to display your VAS ideas and products in a way that suits them</p>
                            </div>
                        </div>

                        <div class="feat-item col-lg-3">
                            <div class="text-center">
                                <span class="icon icon-basic-cloud"></span>
                                <h5>Digital Advertising</h5>
                                <!-- <h6>Logo & Motion Design</h6> -->
                                <p>We work to market your products in an attractive and elegant manner</p>
                            </div>
                        </div>
                        <div class="feat-item col-lg-3">
                            <div class="text-center">
                                <span class="icon icon-basic-webpage"></span>
                                <h5>Media Production</h5>
                                <!-- <h6>Logo & Motion Design</h6> -->
                                <p>We are working on producing visual and audio content that has an impact on society</p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <!-- <div class="offset-lg-2 col-lg-8 offset-md-1 col-md-10 text-center mb-80">
                            <h4 class="extra-title">About Our <span>Creative</span> Company</h4>
                            <p>make people’s life easier by building reliable ICT solutions and to deliver a high quality content that provides a great value to the community.</p>
                        </div> -->

                        <div class="feat-item col-lg-3">
                            <div class="text-center">
                                <span class="icon icon-basic-message-txt"></span>
                                <h5>PSMS services</h5>
                                <!-- <h6>Modern & Clean Design</h6> -->
                                <p>Provide innovative content in the form of short messages</p>
                            </div>
                        </div>

                        <div class="feat-item col-lg-3">
                            <div class="text-center">
                                <span class="icon icon-basic-cloud"></span>
                                <h5>Digital Services</h5>
                                <!-- <h6>Web & Mobile Development</h6> -->
                                <p>Providing innovative audio-visual content in the form of digital portals</p>
                            </div>
                        </div>

                        <div class="feat-item col-lg-3">
                            <div class="text-center">
                                <span class="icon icon-basic-headset"></span>
                                <h5>IVR</h5>
                                <!-- <h6>Logo & Motion Design</h6> -->
                                <p>Presenting innovative audio content in the form of audio interaction</p>
                            </div>
                        </div>
                        <div class="feat-item col-lg-3">
                            <div class="text-center">
                                <span class="icon icon-basic-share"></span>
                                <h5>RBT</h5>
                                <!-- <h6>Logo & Motion Design</h6> -->
                                <p>Customize caller waiting tones</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="curve curve-gray-b curve-bottom"></div>
            </div>

            <div class="tabs-section section-padding bg-gray">
                <div class="container">
                    <div class="row">

                        <ul class="col-md-12 nav nav-pills tabs" id="pills-tab" role="tablist">
                            <li class="nav-item one active">
                                <a class="nav-link active" id="pills-home-tab" data-toggle="pill" href="#tab1" role="tab" aria-controls="tab1" aria-selected="true">About Us</a>
                            </li>
                            <li class="nav-item two">
                                <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#tab2" role="tab" aria-controls="tab2" aria-selected="false">Our Mission</a>
                            </li>
                            <li class="nav-item three">
                                <a class="nav-link" id="pills-contact-tab" data-toggle="pill" href="#tab3" role="tab" aria-controls="tab3" aria-selected="false">Why Us?</a>
                            </li>
                        </ul>
                        
                        <div class="tab-content col-lg-12" id="pills-tabContent">
                            <div class="tab-pane fade show active" id="tab1" role="tabpanel" aria-labelledby="pills-home-tab">
                                <div class="row">
                                    <div class="left col-lg-6">
                                        <div class="about box-white">
                                            <h5 class="title">About Us</h5>
                                            <h6 class="sub-title">We Are A Creative Company</h6>
                                            <p>We are a VAS company that owns and develop mobile content that covers a verity of categories like News, Health & Fitness, Family, Entertainment, Islamic, Sport, and more. And due to our solid background in Information technology we also provide software solutions to businesses including VAS systems for CPs, Call Center systems, Bulk and Promotions, Mobile apps, and website development.</p>

                                            <!-- Skills -->

                                            <!-- <div class="skills mt-40">
                                                <div class="skill-item mb-30">
                                                    <h6>Web Design</h6>
                                                    <div class="skill-progress">
                                                        <div class="progres" data-value="90%"></div>
                                                    </div>
                                                </div>
                                                <div class="skill-item mb-30">
                                                    <h6>Branding</h6>
                                                    <div class="skill-progress">
                                                        <div class="progres" data-value="75%"></div>
                                                    </div>
                                                </div>
                                                <div class="skill-item">
                                                    <h6>Development</h6>
                                                    <div class="skill-progress">
                                                        <div class="progres" data-value="80%"></div>
                                                    </div>
                                                </div>
                                            </div> -->
                                        </div>
                                    </div>
                                    <div class="col-lg-6 image">
                                        <div class="img">
                                            <img src="../../assets/img/2.jpg" alt="">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="tab2" role="tabpanel" aria-labelledby="pills-home-tab">
                                <div class="row">
                                    <div class="left col-lg-6">
                                        <div class="mission box-white">
                                            <h5 class="title">Our Mission</h5>
                                            <h6 class="sub-title">We Are A Creative Company</h6>
                                            <p class="mb-10"><b>We’re make people’s life easier which means</b>we’ve building reliable ICT solutions and to deliver <b>a high quality content</b> that provides a great value to the community.</p>
                                            <!-- <p>You’ll form a lasting relationship with us, collaboration is central to everything we do. We’ll push you out of your comfort zone from time-to-time, but this is where you’ll shine. Bottom line is we want you to succeed at Faculty.</p> -->
                                        </div>
                                    </div>
                                    <div class="col-lg-6 image">
                                        <div class="img">
                                            <img src="../../assets/img/3.jpg" alt="">
                                            <a class="vid" href="">
                                                <span class="vid-butn">
                                                    <i class="fas fa-play-circle"></i>
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="tab3" role="tabpanel" aria-labelledby="pills-home-tab">
                                <div class="row">
                                    <div class="left col-lg-6">
                                        <div class="why-us box-white">
                                            <h5 class="title">Why Choose Us?</h5>
                                            <h6 class="sub-title">Attention to Details</h6>
                                            <p>It’s our attention to the small stuff, scheduling of timelines and keen project management that makes us stand out from the rest. We are creative, while keeping a close eye on the calendar and your budget.</p>
                                            <ul class="feat mt-30 row">
                                                <li class="col-sm-4">
                                                    <span class="icon icon-basic-display"></span>
                                                    <h6>A Plan for Success</h6>
                                                </li>
                                                <li class="col-sm-4">
                                                    <span class="icon icon-basic-picture-multiple"></span>
                                                    <h6>Creativity</h6>
                                                </li>
                                                <li class="col-sm-4">
                                                    <span class="icon icon-basic-headset"></span>
                                                    <h6>Experts Only</h6>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 image">
                                        <div class="img owl-carousel owl-theme carousel-single">
                                            <img src="../../assets/img/4.jpg" alt="">
                                            <img src="../../assets/img/5.jpg" alt="">
                                            <img src="../../assets/img/2.jpg" alt="">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>

        <!-- End Hero ====
        ======================================= -->
</template>

<script>
export default {
   name: 'AboutOurCreative'
}
</script>

<style>

</style>