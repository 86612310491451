<template>
  <about-our-creative/>
  <our-process/>
  <our-products/>
  <talent-team/>
  <our-clients/>
  <app-contacts/>
</template>

<script>
import AboutOurCreative from './AboutOurCreative.vue'
import AppContacts from './AppContacts.vue'
import OurClients from './OurClients.vue'
import OurProcess from './OurProcess.vue'
import OurProducts from './OurProducts.vue'
import TalentTeam from './TalentTeam.vue'
export default {
  components: { AboutOurCreative, OurProcess, TalentTeam, OurClients, OurProducts, AppContacts },
  name: 'AppHome'
}
</script>

<style>

</style>