<template>
    <!-- ========================================= Start Testimonials -->
        <section class="team section-padding bg-img bg-fixed pos-re" data-overlay-dark="7" data-background="static/img/bg4.jpg">
            <div class="container">
                <div class="row">
                    <div class="section-head offset-md-2 col-md-8 offset-lg-3 col-lg-6">
                        <!-- <h4>What's <span>Clients</span> Says?</h4> -->
                        <h4><span>Our</span> Clients</h4>
                        <p>We believe in not just providing a service to our clients but also forming a relationship based on understanding our client’s needs and then developing solutions based on those needs.</p>
                    </div>
                    <div class="owl-carousel owl-theme">
                        <div class="item">
                            <div class="team-img">
                                <img src="../../assets/img/clients/Matrix.png" alt="">
                            </div>
                            <div class="info">
                                <h6>Matrix For Telecommute  Ltd.co</h6>
                            </div>
                        </div>
                    
                        <div class="item">
                            <div class="team-img">
                                <img src="../../assets/img/clients/Albatross.png" alt="">
                            </div>
                            <div class="info">
                                <h6>Albatross Engineering Ltd.co</h6>
                            </div>
                        </div>

                        <div class="item">
                            <div class="team-img">
                                <img src="../../assets/img/clients/Oxygen.png" alt="">
                            </div>
                            <div class="info">
                                <h6>Oxygen Ltd.co</h6>
                            </div>
                        </div>

                        <div class="item">
                            <div class="team-img">
                                <img src="../../assets/img/clients/Lotus.png" alt="">
                            </div>
                            <div class="info">
                                <h6>Lotus West Ltd.co</h6>
                            </div>
                        </div>

                        <div class="item">
                            <div class="team-img">
                                <img src="../../assets/img/clients/Common.jpg" alt="">
                            </div>
                            <div class="info">
                                <h6>Common Media Center Ltd.co</h6>
                            </div>
                        </div>
                        <div class="item">
                            <div class="team-img">
                                <img src="../../assets/img/clients/DigitalPlus.jpg" alt="">
                            </div>
                            <div class="info">
                                <h6>Digital Plus Ltd.co</h6>
                            </div>
                        </div>
                        <div class="item">
                            <div class="team-img">
                                <img src="../../assets/img/clients/IvoryCell.png" alt="">
                            </div>
                            <div class="info">
                                <h6>IvoryCell Ltd.co</h6>
                            </div>
                        </div>
                        <div class="item">
                            <div class="team-img">
                                <img src="../../assets/img/clients/Samsson.png" alt="">
                            </div>
                            <div class="info">
                                <h6>Samsson Ltd.co</h6>
                            </div>
                        </div>
                        <div class="item">
                            <div class="team-img">
                                <img src="../../assets/img/clients/Elgedees.png" alt="">
                            </div>
                            <div class="info">
                                <h6>Elgedees Ltd.co</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="curve curve-top"></div>
        </section>
        <!-- End Testimonials =========================================== -->
</template>

<script>
export default {
   name: 'OurClients'
}
</script>

<style>

</style>