<template>
  <app-loading/>
  <app-navbar/>
  <app-header/>
  <app-home/>
  <app-footer/>
</template>

<script>
import AppFooter from '../components/layouts/AppFooter.vue'
import AppHeader from '../components/layouts/AppHeader.vue'
import AppNavbar from '../components/layouts/AppNavbar.vue'
import AppHome from '../components/Pages/AppHome.vue'
import AppLoading from '../components/Pages/AppLoading.vue'
export default {
  components: { AppNavbar, AppHeader, AppFooter, AppHome, AppLoading },
  name: 'Home',
}
</script>
