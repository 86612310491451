<template>
    <!-- ========================================= Start Loading -->
    <div class="loading">
    	<div class="text-center middle">
    		<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    	</div>
    </div>
    <!-- End Loading =========================================== -->
</template>

<script>
export default {
  name: 'AppLoading'
}
</script>

<style>

</style>