<template>
   <!-- ========================================= Start Footer -->
        <footer class="text-center pos-re">
            <div class="container">
                <!-- Logo -->
                <router-link to="/" class="logo">
                    <img src="static/img/logo-light.png" alt="SudaVas">          
                </router-link>
                <div class="social">
                    <a href="#0"><i class="fab fa-facebook-f"></i></a>
                    <a href="#0"><i class="fab fa-twitter"></i></a>
                    <a href="#0"><i class="fab fa-linkedin-in"></i></a>
                    <a href="#0"><i class="fab fa-behance"></i></a>
                    <a href="#0"><i class="fab fa-pinterest-p"></i></a>
                </div>
                <p>&copy; 2022 SudaVas. All Rights Reserved.</p>
            </div>
            <div class="curve curve-top curve-center"></div>
        </footer>
        <!-- End Footer =========================================== -->
</template>

<script>
export default {
   name: 'AppFooter'
}
</script>

<style>

</style>